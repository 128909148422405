<template>
  <div class="main-panel">
    <div
      class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="w-75">
        <div class="mb-4">
          <svg width="89" height="89" viewBox="0 0 89 89" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M45.5 17C36.3873 17 29 24.3873 29 33.5C29 42.6127 36.3873 50 45.5 50C47.4339 50 49.2838 49.6686 51.0003 49.0619C52.5624 48.5098 54.2764 49.3285 54.8285 50.8907C55.3807 52.4528 54.5619 54.1668 52.9997 54.7189C50.65 55.5494 48.1244 56 45.5 56C33.0736 56 23 45.9264 23 33.5C23 21.0736 33.0736 11 45.5 11C57.9264 11 68 21.0736 68 33.5C68 34.4747 67.9379 35.4363 67.817 36.3808C67.6066 38.0243 66.1039 39.1861 64.4604 38.9757C62.817 38.7654 61.6552 37.2626 61.8655 35.6192C61.9541 34.9266 62 34.2194 62 33.5C62 24.3873 54.6127 17 45.5 17Z"
              fill="#0055FE"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M78 41H75.3945C73.3198 44.5869 69.4417 47 65 47H48C46.3433 47 45 45.6567 45 44C45 42.3433 46.3433 41 48 41H65C68.3137 41 71 38.3135 71 35V33H71.0688C71.0256 32.8428 71 32.6772 71 32.5C71 17.8643 59.1355 6 44.5 6C29.8645 6 18 17.8643 18 32.5V37C18 39.209 16.2092 41 14 41H11C6.58179 41 3 37.4185 3 33C3 28.5815 6.58179 25 11 25H12.8696C16.2554 10.6665 29.1321 0 44.5 0C59.8679 0 72.7446 10.6665 76.1304 25H78C82.4182 25 86 28.5815 86 33C86 37.4185 82.4182 41 78 41ZM76.9465 33H77V35H78C79.1045 35 80 34.1045 80 33C80 31.8955 79.1045 31 78 31H76.9661C76.9885 31.4971 77 31.9971 77 32.5C77 32.6772 76.9802 32.8428 76.9465 33ZM12 35V32.5C12 31.9971 12.0115 31.4971 12.0339 31H11C9.89551 31 9 31.8955 9 33C9 34.1045 9.89551 35 11 35H12Z"
              fill="#F68B1F"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 67C13.1634 67 6 74.1634 6 83V86C6 87.6569 4.65685 89 3 89C1.34315 89 0 87.6569 0 86V83C0 70.8497 9.84974 61 22 61H67C79.1503 61 89 70.8497 89 83V86C89 87.6569 87.6569 89 86 89C84.3431 89 83 87.6569 83 86V83C83 74.1634 75.8366 67 67 67H22Z"
              fill="#0055FE"
            />
          </svg>
        </div>
        <div class="mb-4">
          ขณะนี้เจ้าหน้าที่กำลังให้บริการ <br />
          ลูกค้าท่านอื่นอยู่ <br />
          กรุณาลองอีกครั้งในภายหลัง
        </div>
      </div>
      <router-link :to="'/'">
        <button class="rounded-pill border-0 end-btn" @click="closeWidget">
          ออกจากการสนทนา
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
// import { useRouter } from 'vue-router'
export default {
  data() {
    return {
      isNetworkProb: true,
    }
  },

  methods: {
    closeWidget() {
      window.parent.postMessage(
        {
          closeWidget: true,
        },
        '*'
      )
    },
  },
  mounted() {
    window.parent.postMessage(
      {
        widgetTitle: 'กำลังติดต่อเจ้าหน้าที่',
      },
      '*'
    )
  },
}
</script>

<style scoped>
.main-panel {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

.chat-panel {
  overflow: scroll;
}
.end-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f68b1f;
  width: 310px;
  height: 44px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 24px;
}
</style>
